
/*import Container from 'react-bootstrap/esm/Container';*/
/*componentes*/
import './App.css';
import Banner from './componentes/banner/Banner';
import Cards from './componentes/cards/Cards';
import Carousel from './componentes/carousel/Carousel';
import UncontrolledExample from './componentes/carousel/UncontrolledExample.jsx'
import NavScroll from './componentes/navbarScroll/NavScroll-home';

import Footer from './componentes/footer/Footer';
/*import Galery from './componentes/galery/galery-componente';import Segmentos from './componentes/cards2/cardsTow.jsx';
import Segmentosb from './componentes/cards2/cards-towb.jsx';*/
/*componentes*/
/** imagens-soluçoes */
import TI from "./img/solution/TI.png"
import SEG from "./img/solution/SEG.png"
import TEL from "./img/solution/TEL.png"
import AUT from "./img/solution/AUT.png"
/** imagens-soluçoes  */
/** imagens-sections  */
import Parceiros from "./img/PACEIROS.png";
import Soluçao from "./img/solucoes.png";
import Segmento from "./img/segmentos.png";
/*import Servir from "./img/serviços.png";*/
/**import Clientes from "./img/clientes.png"; */
/** imagens-sections  */
/** imagens-segmentos */
import controle from "./img/segmento/controleacesso.png"
import equipamentos from "./img/segmento/equipamentos.png"
import energia from "./img/segmento/energiasolar.png"
import opitica from "./img/segmento/sistemasopticos.png"
import servidores from "./img/segmento/racksacessorios.png"
import telefoneia from "./img/segmento/telefonia.png"
import emergencia from "./img/segmento/iluminacaoemergencia.png"
import gpon from "./img/segmento/gpon.png"
import cabeamento from "./img/segmento/cabeamentoestruturado.png"
import segurança from "./img/segmento/cameras.png"

/** imagens-segmentos */
/**segmentos-b */
import energiaseg from "./img/icons-seg/energia.png"
import energiasegb from "./img/icons-seg/energiab.png"
import medsega from "./img/icons-seg/med-seg.png"
import medseg from "./img/icons-seg/med-sega.png"
import agrosega from "./img/icons-seg/agro-segc.png"
import agroseg from "./img/icons-seg/agro-sega.png"
import bagsega from "./img/icons-seg/bag-seg.png"
import bagseg from "./img/icons-seg/bag-sega.png"
import capsega from "./img/icons-seg/cap-seg.png"
import capseg from "./img/icons-seg/cap-sega.png"
import estudantesega from "./img/icons-seg/estudante-seg.png"
import estudanteseg from "./img/icons-seg/estudante-sega.png"
import investsega from "./img/icons-seg/invest-seg.png"
import investseg from "./img/icons-seg/invest-sega.png"
import industriasega from "./img/icons-seg/industria-seg.png"
import industriaseg from "./img/icons-seg/industria-sega.png"
import govseg from "./img/icons-seg/gov-seg.png"
import govsega from "./img/icons-seg/gov-segb.png"
import logseg from "./img/icons-seg/log-seg.png"
import logsega from "./img/icons-seg/log-sega.png"
/**segmentos-b */
/** imagens-segmentos b*/
import controleb from "./img/segmentob/controleacesso_b.png"
import equipamentosb from "./img/segmentob/equipamentos_b.png"
import energiab from "./img/segmentob/energiasolar_b.png"
import opiticab from "./img/segmentob/sistemasopticos_b.png"
import servidoresb from "./img/segmentob/racksacessorios_b.png"
import telefoneiab from "./img/segmentob/telefonia_b.png"
import emergenciab from "./img/segmentob/iluminacaoemergencia_b.png"
import gponb from "./img/segmentob/gpon_b.png"
import cabeamentob from "./img/segmentob/cabeamentoestruturado_b.png"
import segurançab from "./img/segmentob/cameras_b.png"
import arowRed from "./img/logo.png"
import { Link } from 'react-router-dom';



/** imagens-segmentosb */
/** clientes style={{width:"10rem" , height:"10rem"}} */

/** clientes */

function App() {
  return (
    <>
      <div className="App">
        <header>
          <NavScroll />


        </header>
        <main>
          <UncontrolledExample />
          <br /><br />
          <section>
            <Banner />
          </section>

        <img id='solucao' className='w-50 ms-4' src={Soluçao} alt='' />

        <div className='w-100 d-flex justify-content-evenly  row '>

            <Cards
              imagem={TI}
              title="TECNOLOGIA"
              title1="DA INFORMAÇÃO"
              descricao="Tecnologia da Informação
          é um conjunto de todas as atividades 
          e soluções providas por recursos de 
          computação que visam a produção,
           o armazenamento, a transmissão, 
          o acesso, a segurança 
          e o uso das informações."
            />
            <Cards
              imagem={SEG}
              title="SEGURANÇA 
          ELETRÔNICA,"
              descricao="Segurança Eletrônica é promovida
          por dispositivos tecnológicos que
          inibem, desencorajam, monitoram, 
         identificam e detectam ações criminosas
         , sem a participação presencial e 
         direta do elemento humano e com
          objetivo definido: resguardar vidas e patrimônio. "
            />
            <Cards
              imagem={TEL}
              title="TELECOM"
              title1="&#160;"
              descricao="As telecomunicações, ou Telecom, 
          são sistemas que permite a troca 
          e informações por distâncias significativas 
          através de meios eletrônicos 
          e pode se referir a todos os tipos de 
          transmissão de voz, dados e vídeo.
          operacionais de uma empresa."
            />
            <Cards
              imagem={AUT}
              title="AUTOMAÇÃO"
              title1="&#160;"
              descricao="O que é Automatização 
          Sua maior finalidade é substituir 
          tarefas muito repetitivas, de 
          baixa importância ou operacionais
           de uma empresa."
            />
          </div>

          <div className='d-flex w-100 flex-column row aling-items-center  m-0'>
             
            {/**<img id='servirços' className='w-50 ms-4 mt-4 hidden '  src={Servir} alt=''/> */}
            <div className='d-flex row  mb-5 p-5 'style={{margin:"0px auto" , width:"90%" ,placeItems:" center"}}>
             {/** servirços */}
             <div className='mx-4 col-sm-1 contant-cards  align-items-center'  >
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={controle} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">CONTROLE ACESSO</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={controleb} alt="" />
                        <h4 className="titulo">CONTROLE ACESSO</h4>

                        <p className="descricao">Em segurança, especialmente segurança física, o termo controle de acesso é uma referência à prática de permitir o acesso a uma propriedade, prédio, ou sala.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={equipamentos} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">EQUIPAMENTOS ATIVOS</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={equipamentosb} alt="" />
                        <h4 className="titulo">EQUIPAMENTOS ATIVOS</h4>

                        <p className="descricao">Ativos de rede são equipamentos específicos que permitem estruturar uma rede de computadores, conectando as máquinas da empresa umas às outras.</p>
                        <Link to="equipamentos" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={energia} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">ENERGIA SOLAR</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={energiab} alt="" />
                        <h4 className="titulo">ENERGIA SOLAR</h4>

                        <p className="descricao">Energia solar corresponde à energia proveniente da luz e do calor emitidos pelo Sol. Essa fonte de energia pode ser aproveitada de forma fotovoltaica</p>
                        <Link to="/energia" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={opitica} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">FIBRA OPTICA</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={opiticab} alt="" />
                        <h4 className="titulo">FIBRA OPTICA</h4>

                        <p className="descricao">Fibra óptica é um filamento flexível e transparente fabricado a partir de vidro ou plástico extrudido e que é utilizado como condutor de elevado rendimento de luz, imagens ou impulsos codificados. Tem diâmetro de alguns micrometros, ligeiramente superior ao de um fio de cabelo humano.</p>
                        <Link to="/fibra" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={servidores} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">RACKS & ACESÓRIOS</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={servidoresb} alt="" />
                        <h4 className="titulo">RACKS & ACESSÓRIOS</h4>

                        <p className="descricao">Rack é uma estrutura geralmente feita de metal no formato de gabinete ou parede, que permite  organizar os diferentes componentes de instalações da rede, como servidores, sistemas de armazenamento, switches, cabos, entre outros equipamentos </p>
                        <Link to="/Racks" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards   align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={telefoneia} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">TELEFONIA</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={telefoneiab} alt="" />
                        <h4 className="titulo">TELEFONIA</h4>

                        <p className="descricao">Telefonia é o campo da tecnologia envolvendo o desenvolvimento, aplicação e implantação de telecomunicações serviços com a finalidade de transmissão eletrónica de voz, fax ou dados, entre partes distantes.</p>
                        <Link to="/Telefonia" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={emergencia} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">ILUMINAÇÃO EMERGÊNCIA</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={emergenciab} alt="" />
                        <h4 className="titulo">ILUMINAÇÃO EMERGÊNCIA</h4>

                        <p className="descricao">Atuamos na instalaçao de Central de Iluminação de Emergência .</p>
                        <Link to="/iluminaçao" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={gpon} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">Gpon</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={gponb} alt="" />
                        <h4 className="titulo">Gpon</h4>

                        <p className="descricao">GPON, significa Gigabit Passive Optical Network, ou Rede Óptica Passiva Gigabit, mas o que é ser uma rede passiva?.</p>
                        <Link to="/Gpon" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={cabeamento} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">CABEAMENTO ESTRUTURADO</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={cabeamentob} alt="" />
                        <h4 className="titulo">CABEAMENTO ESTRUTURADO</h4>

                        <p className="descricao">Um sistema de cabeamento estruturado é composto por cabos, conectores, condutas e dispositivos que permite a conectividade à rede de telecomunicações.</p>
                        <Link to="/cabeamento" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            {/** servirços */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={segurança} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta">SEGURANÇA ELETRÔNICA</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={segurançab} alt="" />
                        <h4 className="titulo">SEGURANÇA ELETRÔNICA</h4>

                        <p className="descricao">A segurança eletrônica são tecnologias, integrados por um sistema eletrônico, que tem como objetivo monitorar ambientes e pessoas para, dessa forma, aumentar a segurança tanto do patrimônio como dos indivíduos.</p>
                        <Link to="/segurança" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
           </div>
            <img id='segmentos' className='w-50 ms-4 mt-5 ' src={Segmento} alt='' />
            <div className='d-flex row  mb-5 p-5 'style={{margin:"0px auto" , width:"90%" ,placeItems:" center"}}>
               {/** segmentos */}
             <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={energiaseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>Energia</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={energiasegb} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>Energia</h4>

                        <p className="descricao">Em segurança, especialmente segurança física, o termo controle de acesso é uma referência à prática de permitir o acesso a uma propriedade, prédio, ou sala.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={medseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>Saúde</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={medsega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>Saúde</h4>

                        <p className="descricao">Ativos de rede são equipamentos específicos que permitem estruturar uma rede de computadores, conectando as máquinas da empresa umas às outras.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={govseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>Setor Público</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={govsega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>Setor Público</h4>

                        <p className="descricao">Energia solar corresponde à energia proveniente da luz e do calor emitidos pelo Sol. Essa fonte de energia pode ser aproveitada de forma fotovoltaica</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={logseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>Logistica</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={logsega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>Logistica</h4>

                        <p className="descricao">Fibra óptica é um filamento flexível e transparente fabricado a partir de vidro ou plástico extrudido e que é utilizado como condutor de elevado rendimento de luz, imagens ou impulsos codificados. Tem diâmetro de alguns micrometros, ligeiramente superior ao de um fio de cabelo humano.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={bagseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>varejo</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={bagsega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>varejo</h4>

                        <p className="descricao">Rack é uma estrutura geralmente feita de metal no formato de gabinete ou parede, que permite  organizar os diferentes componentes de instalações da rede, como servidores, sistemas de armazenamento, switches, cabos, entre outros equipamentos </p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards   align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={investseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta" style={{textTransform:" uppercase"}}>Financeiro</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={investsega} alt="" />
                        <h4 className="titulo">Financeiro</h4>

                        <p className="descricao">Telefonia é o campo da tecnologia envolvendo o desenvolvimento, aplicação e implantação de telecomunicações serviços com a finalidade de transmissão eletrónica de voz, fax ou dados, entre partes distantes.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={agroseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>Agronegócio</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={agrosega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>Agronegócio</h4>

                        <p className="descricao">Atuamos na instalaçao de Central de Iluminação de Emergência .</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={capseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>construção sivil</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={capsega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>construção sivil</h4>

                        <p className="descricao">GPON, significa Gigabit Passive Optical Network, ou Rede Óptica Passiva Gigabit, mas o que é ser uma rede passiva?.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={estudanteseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>Educação</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={estudantesega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>Educação</h4>

                        <p className="descricao">Um sistema de cabeamento estruturado é composto por cabos, conectores, condutas e dispositivos que permite a conectividade à rede de telecomunicações.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** segmentos */}
            {/** segmentos */}
            <div className='mx-4 col-sm-1 contant-cards  align-items-center'>
              <div className=" row m-auto  ">

                <div className="contant-cards m-auto my-2">
                  <div className="container-cards shadow">
                    <div className="conteudo">
                      <img src={industriaseg} alt="" className="img-icon-card-black oculta" />
                      <h4 className="titulo-black oculta"style={{textTransform:" uppercase"}}>indústia</h4>
                      <div className="hiden">
                        <img className="img-icon-card " src={industriasega} alt="" />
                        <h4 className="titulo"style={{textTransform:" uppercase"}}>indústia</h4>

                        <p className="descricao">A segurança eletrônica são tecnologias, integrados por um sistema eletrônico, que tem como objetivo monitorar ambientes e pessoas para, dessa forma, aumentar a segurança tanto do patrimônio como dos indivíduos.</p>
                        <Link to="/controle" className="saiba" >ver mais <img src={arowRed} className="right-arow red-off " alt="" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            {/** servirços */}
            </div>
          </div>
          <div>
            {/*<Segmentos 
          Thumbnail={controle} 
          Thumbnailhover={controleb} 
          title="CONTROLE ACESSO" 
          descri="
          Em segurança, especialmente segurança física, o termo controle de acesso é uma referência à prática de permitir o acesso a uma propriedade, prédio, ou sala, apenas para pessoas autorizadas fazendo 
          uso de tecnologias para Interfonia , Fechaduras , Automatizadores , Comunicação condominial ,Controle de acesso corporativo"
          />
          
          <Segmentos 
          Thumbnail={equipamentos} 
          Thumbnailhover={equipamentosb} 
          title="EQUIPAMENTOS ATIVOS" 
          descri="Ativos de rede são equipamentos específicos que permitem estruturar uma rede de computadores, conectando as máquinas da empresa umas às outras e também conectando a organização à internet. 
          São exemplos de ativos de rede: 
          
          Hubs, 
          Switches, 
          Roteadores, 
          Servidores, 
          Placas de rede, 
          Firewall. "/>
          <Segmentos 
          Thumbnail={energia} 
          Thumbnailhover={energiab} 
          title="ENERGIA SOLAR" 
          descri="
          Atinja seus objetivos. Nós estamos prontos para customizar a solução de energia adequada para você.
          Comercial e Industrial
          Maior controle e independência. Proteje você contra a volatilidade do preço da tarifa
          Aumenta a competitividade do seu negócio através da redução de custos
          Simplicidade, eficiência e flexibilidade para sua empresa .
          "/>
          
          <Segmentos 
          Thumbnail={opitica} 
          Thumbnailhover={opiticab} 
          title="FIBRA OPITICA" 
          descri="Atuamos na elaboração e implementação de sistemas opiticos com uso nas areas de .
          Transmissão de dados: As fibras ópticas podem ser usadas para transmitir dados de internet, telefone, televisão, redes, rádio etc.
          Sensores: Por meio das fibras ópticas, é possível construir uma grande variedade de sensores .
        
          "/>
          <Segmentos 
          Thumbnail={servidores} 
          Thumbnailhover={servidoresb} 
          title="RACKS & ACESSORIOS" 
          descri="Atuamos na elaboração e implementação de Racks e Acessorios.
          Rack é uma estrutura geralmente feita de metal no formato de gabinete ou parede, que permite  organizar os diferentes componentes de instalações da rede, como servidores, sistemas de armazenamento, switches, cabos, entre outros equipamentos "/>
          <Segmentos 
          Thumbnail={telefoneia} 
          Thumbnailhover={telefoneiab} 
          title="TELEFONIA" 
          descri="Telefonia é o campo da tecnologia envolvendo o desenvolvimento, aplicação e implantação de telecomunicações serviços com a finalidade de transmissão eletrónica de voz, fax ou dados, entre partes distantes."/>
          <Segmentos 
          Thumbnail={emergencia} 
          Thumbnailhover={emergenciab} 
          title="ILUMINAÇÃO EMERGÊNCIA" 
          descri="atuamos na instalaçao de Central de Iluminação de Emergência .
          Iluminação de Emergência de Aclaramento é uma luminária que serve para clarear o ambiente e especialmente o piso. 
          e Balizamento ou Sinalização
          É o tipo de iluminação com letras e/ou símbolos, e que orientam os ocupantes para a rota de saída mais segura."/>
          <Segmentos 
          Thumbnail={gpon} 
          Thumbnailhover={gponb} 
          title="GPON" 
          descri="GPON, significa Gigabit Passive Optical Network, ou Rede Óptica Passiva Gigabit, mas o que é ser uma rede passiva?.  Isto quer dizer que, entre a OLT e a ONU, ou seja, entre o concentrador da rede e os clientes, não existe nenhum elemento ativo/energizado."/>
          <Segmentos 
          Thumbnail={cabeamento} 
          Thumbnailhover={cabeamentob} 
          title="CABEAMENTO ESTRUTURADO" 
          descri=" projetos que atendem a necessidade de empresas de todos os portes ou setores. Com tantas tecnologias disponíveis e a necessidade de uma comunicação cada vez mais rápida e segura, não dá para relaxar quando o assunto é infraestrutura.
          Além disso, um sistema organizado e bem montado é fundamental. "/>
          <Segmentos 
          Thumbnail={segurança} 
          Thumbnailhover={segurançab} 
          title="SEGURANÇA ELETRÔNICA" 
          descri="especializados na elaboração e excução de projetos. 
          e instalação de equipamentos.
          PORTÕES ELETRÔNICOS
          PORTEIROS ELETRÔNICOS
          CONTROLES PARA PORTOES
          CERCAS ELÉTRICAS
          ALARMES
          CFTV
          CÂMERAS DE SEGURANÇA
          INTERFONES"/>*/}
          </div>
        </main>

        <div className='w-100 ' style={{ marginBottom: "120px" }}>
          <img id='parceiros' className='w-50 ms-4 ' style={{ marginBottom: "90px", marginTop: "120px" }} src={Parceiros} alt='' />
          <Carousel />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
