import Footer from "../../componentes/footer/Footer"
import NavScroll from "../../componentes/navbarScroll/NavScroll"
const Gpon = (props) =>{
    return(
        <>
        <NavScroll/>
        <main className="">
           <h1>GPON</h1>
        </main>
        <Footer/>
        </>
    )
}
export default Gpon;