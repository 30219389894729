import Container from 'react-bootstrap/Container';


import Card from 'react-bootstrap/Card';

/*import facebook from "../../img/social icons/facebook-vermelho.png";
import instagram from "../../img/social icons/canal-vermelho.png";
import linkdin from "../../img/social icons/linkedin-vermelho48.png";
import youtube from "../../img/social icons/insta-vermelho.png";

                 <img src={facebook} alt="" className="social" />
                <img src={instagram} alt="" className="social" />
                <img src={linkdin} alt="" className="social" />
                <img src={youtube} alt="" className="social" />

*/

import './style.css';
function Banner(props) {
    return (
        <Container className='banner'>
            <div className='container-social'>
                <a href="https://www.facebook.com/jamp.ti.56" target="_blank" rel="noopener noreferrer"><div className="social" ></div></a>
                <a href="https://www.instagram.com/jamp__ti/" target="_blank" rel="noopener noreferrer"><div className="social1" ></div></a>
               <a href="http://" target="_blank" rel="noopener noreferrer"> <div className="social2" ></div></a>
                <a href="http://" target="_blank" rel="noopener noreferrer"><div className="social3" ></div></a>
            </div>
            <Card className='banner-card'>
                <p className="fs-6">{props.banner}</p>
            </Card>
        </Container>
    );
}

export default Banner;