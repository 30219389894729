
import Footer from '../componentes/footer/Footer';
import NavScroll from '../componentes/navbarScroll/NavScroll';
import TextosBanner from '../componentes/textos/textos';

function segmentos() {
  return (
    <>
     <NavScroll/>
     <TextosBanner/>
     <Footer/>
    </>
   
  );
}

export default segmentos;