import Footer from "../../componentes/footer/Footer"
import telImg from "../img/telefonia/voipbaner2.png"
import telImg2 from "../img/telefonia/voipbaner3.png"
import voipp from "../img/telefonia/voipbaner.png"
import NavScroll from "../../componentes/navbarScroll/NavScroll"
import voip from "../img/telefonia/VOIP.png"
import telecom from "../img/telefonia/soft-fone.png"
import cell from "../img/telefonia/cell.png"
import tell from "../img/telefonia/atendente.png"
import "./style-solucao.css"
const Telefonia = (props) => {
  return (
    <>
      <NavScroll />
      <main className="w-100 d-flex flex-column">
        <img className="my-5" src={voip} srcSet="" alt="" style={{ width: "700PX", margin: "0PX AUTO" }} />
        <h4 className="   my-4"style={{ width: "300PX", marginLeft: "550px" }}>O QUE É TELEFONIA?</h4>
        <p className="text-secondary  justify gohtic mx-auto" style={{ width: "700PX",lineHeight:"20px" ,fontSize:"1.1pc"  }}>Telefonia  é o campo da tecnologia envolvendo o<span className="text-danger fs-5 gohtic"> desenvolvimento,</span>
          aplicação e implantação de telecomunicações serviços<span className="text-danger gohtic"> </span>
          com a finalidade de transmissão eletrónica de <span className="text-danger fs-5 gohtic"> voz, fax ou dados,  </span>entre partes distantes.</p>

        <div className="d-flex justify-content-around  " style={{ width: "900PX", height: "300px", margin: "30PX AUTO" }}>
          <div className="d-flex flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="w-75" src={telecom} alt="" srcset="" />
            <p className="gohtic  fs-6 my-2 " style={{ color: "rgba(185,58,62,255)" }}>Soft-fone</p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="" src={cell} alt="" srcset=""  style={{ width: "70PX", margin: "10PX AUTO" }}/>
            <p className="gohtic  fs-6 my-3 " style={{ color: "rgba(185,58,62,255)" }}>Central telefonica</p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="" src={tell} alt="" srcset="" style={{ width: "70PX", margin: "0PX AUTO" }} />
            <p className="gohtic fs-6 my-2 " style={{ color: "rgba(185,58,62,255)" }}>Call Center</p>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center text-center align-items-center">
        <h4 className="  my-1 w-50 " style={{ color: "rgba(185,58,62,255)" }}>TELEFONIA COMUM OU VOIP ?</h4>
          <p className="gohtic  my-5  text-secondary justify"style={{ width: "700PX",fontSize:"1.1pc"  }}>As duas formas de telefonia demonstram duas formas bem diferenciadas de operar. Portanto atentem-se às listas de pós e contras, estabelecidas para escolher qual melhor atende à sua empresa:</p>
          
        </div>
        <div className="w-100 d-flex" style={{ backgroundColor: "rgba(185,58,62,255)" }} >
          <img className="w-25 mx-auto my-5" src={telImg2} srcSet="" alt="" style={{}} />
        </div>
        <div className="d-flex ">
          <div className="w-100 my-5 d-flex flex-column">
            <h1 className="  mx-auto  my-5 gohtic"> Telefonia  <span className="text-danger gohtic">Convencional</span></h1>

            <img className="w-50 my-3 mx-auto" src={telImg} srcSet="" alt="" />
            <div className="d-flex ">
<div className="text-center w-50 d-flex flex-column justify-content-center mx-auto">
<h4 className="fs-2" style={{ color: "rgba(185,58,62,255)" }}>Prós</h4>

<p className="d-flex flex-column text mx-auto w-75 justify">
 Disponibilidade
, Agilidade
, Credibilidade
, Qualidade da ligação
, Sistema de alimentação feito pela central telefônica</p>
</div>
</div>
          </div>
          <div className="w-100 my-5 d-flex flex-column">
            <h1 className="  mx-auto  my-5 gohtic"> Telefonia IP <span className="text-danger gohtic">ou voip</span></h1>
            <img className="w-50 my-5 mx-auto" src={voipp} srcSet="" alt="" />
            <div className="d-flex">
<div className="w-50 text-center flex-column justify-content-center mx-auto">
<h4 className="fs-2"  style={{ color: "rgba(185,58,62,255)" }}>Prós</h4>

<p className="d-flex flex-column text mx-auto w-75 justify">Economia de banda com supressão de silêncio
, Baixo custo
, Praticidade
, Facilidade no funcionamento
, Qualidade</p></div>

</div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
export default Telefonia;