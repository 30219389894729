
import Certifica from '../componentes/certifica/certifica';
import Footer from '../componentes/footer/Footer';
import NavScroll from '../componentes/navbarScroll/NavScroll';
function Certificacos() {
    return(
        <>
        <NavScroll/>
           <Certifica/>
           <Footer/>
        </>
    )
}
export default Certificacos;