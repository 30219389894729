
import Footer from '../componentes/footer/Footer';
import NavScroll from '../componentes/navbarScroll/NavScroll';
function Servircos() {
    return(
        <>
        <NavScroll/>
        <Footer/>
        </>
        
    )
}
export default Servircos;