import RN6 from "../../img/cards-nr/NR-6.png"
import RN10 from "../../img/cards-nr/NR-10.png"
import RN35 from "../../img/cards-nr/NR-35.png"
import RN18 from "../../img/cards-nr/NR-18.png"
import RN20 from "../../img/cards-nr/NR-20.png"
import SOLU from "../../img/CERTIFICAÇÕES.png"
import RN24 from "../../img/cards-nr/NR-24.png"
import RN17 from "../../img/cards-nr/NR-17.png"
import RN26 from "../../img/cards-nr/NR-26.png"
import RN33 from "../../img/cards-nr/NR-33.png"
 

const Certifica = () => { 
    return (
        <div className="container-fluid text-center  ">

            <div className="row  m-5 ">
                 <h1 className="my-5"><img className="w-75 mx-auto" src={SOLU} alt="" srcset="" /></h1>
                <div class="card col-1 m-auto my-2 border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN6} class="card-img-top " alt="..." />
                    <h5 className="mt-2 text-stone-900" >Utilização de Equipamentos de Proteção Individual<br/> NR-6</h5>
                    <div class="card-body  ">
                        <p class="card-text fs-6">
                        A NR 6 é a Norma Regulamentadora que estabelece as medidas que devem ser tomadas em relação à aquisição, à distribuição e à utilização de Equipamentos de Proteção Individual (EPI) nas empresas. Por EPI, entende-se todo dispositivo ou produto de uso individual que se destina à proteção do profissional</p>
                    </div>
                </div>
                <div class="card  m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN10} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Instalações e Serviços com Eletricidade<br/> NR-10</h5>
                    <div class="card-body">
                        <p class="card-text fs-6">
                        NR-10 é a Norma Regulamentadora emitida pelo Ministério do Trabalho e Emprego do Brasil que tem por objetivo garantir a segurança e a saúde dos trabalhadores que interagem nas instalações e serviços com eletricidade.</p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN17} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Ergonomia<br/>NR-17</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        A norma tem como objetivo estabelecer os parâmetros que permitam a adaptação das condições de trabalho às características psicofisiológicas dos trabalhadores, de modo a proporcionar um máximo de conforto, segurança e desempenho eficiente.</p>
                    </div>
                </div> 
                
            </div>
            <div className="row m-5">
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN18} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Setorização de Ambientes<br/> NR-18</h5>
                    <div class="card-body">
                        <p class="card-text">
                        Conforme critérios da Portaria SIT nº 787, de 27 de novembro de 2018, a Norma Regulamentadora NR-18 é definida como Norma Setorial, ou seja, é uma norma que regulamenta a execução do trabalho em setores ou atividades econômicos específicos</p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN20} class="card-img-top"  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Execução do Trabalho com Inflamáveis e Combustíveis <br/>NR-20</h5>
                    <div class="card-body">
                        <p class="card-text">
                        A Norma Regulamentadora nº 20 (NR-20), conforme classificação estabelecida na Portaria SIT n° 787, de 29 de novembro de 2018, é norma especial, posto que regulamenta a execução do trabalho com inflamáveis e combustíveis, considerando as atividades, instalações e equipamentos utilizados, sem estar condicionada a setores ...</p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN24} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >- Condições Sanitárias e de Conforto nos Locais de Trabalho <br/>NR-24</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Esta norma estabelece as condições mínimas de higiene e de conforto a serem observadas
                        pelas organizações, devendo o dimensionamento de todas as instalações regulamentadas por esta NR
                        ter como base o número de trabalhadores usuários do turno com maior contingente. </p>
                    </div>
                </div>
                
            </div>
            <div className="row m-5">
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN26} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Sinalização de Segurança <br/>NR-26</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Norma Regulamentadora que apresenta informações relativas à Sinalização de Segurança no seu Meio de Ambiente de Trabalho. Ela é responsável pela identificação dos equipamentos de segurança, delimitação de áreas e identificação de tubulações de líquidos e gases.</p>
                    </div>
                </div>
            <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN33} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >SEGURANÇA E SAÚDE NOS TRABALHOS EM ESPAÇOS CONFINADOS <br/>NR-33</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Esta Norma Regulamentadora tem como objetivo estabelecer os requisitos para a caracterização dos espaços confinados, os critérios para o gerenciamento de riscos ocupacionais em espaços confinados e as medidas de prevenção, de forma a garantir a segurança e a saúde dos trabalhadores que interagem direta ou indiretamente com estes espaços.</p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN35} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Trabalho em Altura <br/>NR-35</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Esta Norma estabelece os requisitos mínimos e as medidas de proteção para o
                        trabalho em altura, envolvendo o planejamento, a organização e a execução, de forma
                        a garantir a segurança e a saúde dos trabalhadores envolvidos direta ou indiretamente
                        com esta atividade.
                        Considera-se trabalho em altura toda atividade executada acima de 2,00 m</p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}; export default Certifica;

/**
 *  <div className="container-fluid text-center  ">

            <div className="row  m-5 ">
                 <h1 className="my-5"><img className="w-75 mx-auto" src={SOLU} alt="" srcset="" /></h1>
                <div class="card col-1 m-auto my-2 border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN6} class="card-img-top " alt="..." />
                    <h5 className="mt-2 text-stone-900" >Utilização de Equipamentos de Proteção Individual<br/> NR-6</h5>
                    <div class="card-body  ">
                        <p class="card-text fs-6">
                        A NR 6 é a Norma Regulamentadora que estabelece as medidas que devem ser tomadas em relação à aquisição, à distribuição e à utilização de Equipamentos de Proteção Individual (EPI) nas empresas. Por EPI, entende-se todo dispositivo ou produto de uso individual que se destina à proteção do profissional</p>
                    </div>
                </div>
                <div class="card  m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN10} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Instalações e Serviços com Eletricidade<br/> NR-10</h5>
                    <div class="card-body">
                        <p class="card-text fs-6">
                        NR-10 é a Norma Regulamentadora emitida pelo Ministério do Trabalho e Emprego do Brasil que tem por objetivo garantir a segurança e a saúde dos trabalhadores que interagem nas instalações e serviços com eletricidade.</p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN17} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Ergonomia<br/>NR-17</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        A norma tem como objetivo estabelecer os parâmetros que permitam a adaptação das condições de trabalho às características psicofisiológicas dos trabalhadores, de modo a proporcionar um máximo de conforto, segurança e desempenho eficiente.</p>
                    </div>
                </div> 
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN18} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Setorização de Ambientes<br/> NR-18</h5>
                    <div class="card-body">
                        <p class="card-text">
                        Conforme critérios da Portaria SIT nº 787, de 27 de novembro de 2018, a Norma Regulamentadora NR-18 é definida como Norma Setorial, ou seja, é uma norma que regulamenta a execução do trabalho em setores ou atividades econômicos específicos</p>
                    </div>
                </div>
            </div>
            <div className="row m-5">

                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN20} class="card-img-top"  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Execução do Trabalho com Inflamáveis e Combustíveis <br/>NR-20</h5>
                    <div class="card-body">
                        <p class="card-text">
                        A Norma Regulamentadora nº 20 (NR-20), conforme classificação estabelecida na Portaria SIT n° 787, de 29 de novembro de 2018, é norma especial, posto que regulamenta a execução do trabalho com inflamáveis e combustíveis, considerando as atividades, instalações e equipamentos utilizados, sem estar condicionada a setores ...</p>
                    </div>
                </div>
               <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN24} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >- Condições Sanitárias e de Conforto nos Locais de Trabalho <br/>NR-24</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Esta norma estabelece as condições mínimas de higiene e de conforto a serem observadas
                        pelas organizações, devendo o dimensionamento de todas as instalações regulamentadas por esta NR
                        ter como base o número de trabalhadores usuários do turno com maior contingente. </p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN26} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Sinalização de Segurança <br/>NR-26</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Norma Regulamentadora que apresenta informações relativas à Sinalização de Segurança no seu Meio de Ambiente de Trabalho. Ela é responsável pela identificação dos equipamentos de segurança, delimitação de áreas e identificação de tubulações de líquidos e gases.</p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN33} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >SEGURANÇA E SAÚDE NOS TRABALHOS EM ESPAÇOS CONFINADOS <br/>NR-33</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Esta Norma Regulamentadora tem como objetivo estabelecer os requisitos para a caracterização dos espaços confinados, os critérios para o gerenciamento de riscos ocupacionais em espaços confinados e as medidas de prevenção, de forma a garantir a segurança e a saúde dos trabalhadores que interagem direta ou indiretamente com estes espaços.</p>
                    </div>
                </div>
                <div class="card col-1 m-auto my-2  border-white hover:bg-amber-200 text-stone-500 p-0" style={{ width: "20rem", height:"34rem" }}>
                    <img src={RN35} class="card-img-top "  alt="..." />
                    <h5 className="mt-2 text-stone-900" >Trabalho em Altura <br/>NR-35</h5>
                    <div class="card-body">
                        <p class="card-text -mt-3">
                        Esta Norma estabelece os requisitos mínimos e as medidas de proteção para o
                        trabalho em altura, envolvendo o planejamento, a organização e a execução, de forma
                        a garantir a segurança e a saúde dos trabalhadores envolvidos direta ou indiretamente
                        com esta atividade.
                        Considera-se trabalho em altura toda atividade executada acima de 2,00 m</p>
                    </div>
                </div>
                
                
            </div>
            <br />
            <br />
            <br />
        </div>
 */