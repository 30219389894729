import './style.css'


const BannerBlog = (props)=>{
    return(
         <div className='container m-auto p-3 '>
          <img className='m-auto w-75' src={props.imagem} alt="" srcset="" />
         </div>
    );
}

export default BannerBlog;