import Footer from "../../componentes/footer/Footer"
import NavScroll from "../../componentes/navbarScroll/NavScroll"
import EnergiaBanner from "../img/energia/Energia-banner2.png"
import Energia from "../img/energia/energia-solar.png"
import PainelEnergia from "../img/energia/solar-painel.png"
import ConversorEnergia from "../img/energia/converção.png"
//import BateryEnergia from "../img/energia/Armazenamento.png"
import EnergiaONgrid from "../img/energia/ongrid.png"
import EnergiaOffgrid from "../img/energia/offgrid.png"
const EnergiaSolar = (props) => {
    return (
        <>
            <NavScroll />
          
            <main className="w-100 d-flex flex-column">
        <img className="my-5" src={Energia} srcSet="" alt="" style={{ width: "700PX", margin: "0PX AUTO" }} />
        <h4 className="  my-4"style={{ width: "700PX", marginLeft: "490px" }}>O QUE É ENERGIA SOLAR?</h4>
        <p className="text-secondary  justify gohtic mx-auto" style={{ width: "700PX",lineHeight:"20px" ,fontSize:"1.1pc"  }}>Definição<span className="text-danger fs-5 gohtic"> Energia solar,</span> é uma fonte de energia primária, proveniente da <span className="text-danger fs-5 gohtic"> Irradiação do sol.</span>
                    Tanto a luz quanto o calor dos raios solares podem ser transformados em outros tipos de <span className="text-danger fs-5 gohtic">Energia ou Combustível.</span></p>
        <div className="d-flex justify-content-around  " style={{ width: "900PX", height: "300px", margin: "30PX AUTO" }}>
          <div className="d-flex flex-column  justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="" src={PainelEnergia} alt="" srcset="" style={{ width: "110PX", margin: "10PX AUTO" }}/>
            <p className="gohtic  fs-6 my-2 " style={{ color: "rgba(185,58,62,255)" }}>CAPTAÇÃO</p>
          </div>
          <div className="d-flex  flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="" src={ConversorEnergia} alt="" srcset=""  style={{ width: "170PX", margin: "25PX AUTO" }}/>
            <p className="gohtic  fs-6 my-3 " style={{ color: "rgba(185,58,62,255)" }}> CONVERSÃO</p>
          </div>
          <div className="d-flex  flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="" src={EnergiaONgrid} alt="" srcset="" style={{ width: "170PX", margin: "16PX AUTO" }} />
            <p className="gohtic fs-6 my-2 " style={{color: "rgba(185,58,62,255)" }}>ARMAZENAMENTO </p>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center text-center align-items-center">
        <h4 className="  my-1 w-50 " style={{ color: "rgba(185,58,62,255)" }}>Energia SOLAR ONGRID OU OFFGRID ?</h4>
          <p className="gohtic  my-5  text-secondary justify"style={{ width: "700PX",fontSize:"1.1pc"  }}>Enquanto o sistema on-grid depende da rede de distribuição, que fornece créditos pela energia excedente, o off-grid utiliza-se de baterias para manter o uso de equipamentos elétricos mesmo em períodos de menor produção.</p>
          
        </div>
        <div className="w-100 d-flex" style={{ backgroundColor: "rgba(185,58,62,255.50)" }} >
          <img className="w-25 mx-auto my-5" src={EnergiaBanner} srcSet="" alt="" style={{}} />
        </div>
        <div className="d-flex ">
          <div className="w-100 my-5 d-flex flex-column">
            <h1 className="  mx-auto  my-5 gohtic"> Geração Fotovoltaica <span className="text-danger gohtic">ON-grid</span></h1>

            <img className=" w-50 my-5 mx-auto" src={EnergiaONgrid} srcSet="" alt=""  />
            <div className="d-flex ">
<div className="text-center w-50 d-flex flex-column justify-content-center mx-auto">
<h4 className="fs-2 " style={{ color: "rgba(185,58,62,255)" }}>Caracteristicas do sitema ongrid</h4>

<p className="d-flex flex-column text mx-auto w-100 justify">
O sistema on-grid, por exemplo, tem até 30% a mais de eficiência, além de permitir o acúmulo de créditos na rede de distribuição, mas, por outro lado, não conta com um sistema de armazenamento independente, sendo obrigatória a sua conexão à rede pública.

Isso quer dizer que, em casos de falha de abastecimento por parte da distribuidora, sistemas de energia solar on-grid também são afetados se estiverem utilizando o serviço.a</p>
</div>
</div>
          </div>
          <div className="w-100 my-5 d-flex flex-column">
            <h1 className="  mx-auto  my-5 gohtic"> Geração Fotovoltaica <span className="text-danger gohtic">Off-grid</span></h1>
            <img className="w-50 my-3  mx-auto" src={EnergiaOffgrid} srcSet="" alt=""/>
            <div className="d-flex">
<div className="w-50 text-center flex-column justify-content-center mx-auto">
<h4 className="fs-2"  style={{ color: "rgba(185,58,62,255)" }}>Caracteristicas do sitema offgrid</h4>

<p className="d-flex flex-column text mx-auto w-100 justify">
  Enquanto isso, no sistema off grid, isso não é problema. Já que não conta com nenhuma ligação com a rede pública, sistemas como esse estão alheios às falhas de distribuição e contam com alimentação contínua, que é proveniente das baterias. 

Apesar disso, seu custo inicial é mais elevado, afinal, as baterias são equipamentos de alta sofisticação tecnológica e sua instalação responde por boa parte do investimento. 
  </p></div>

</div>
          </div>
        </div>
      </main>
            <Footer />
        </>
    )
}
export default EnergiaSolar;