
import Footer from '../componentes/footer/Footer';
import NavScroll from '../componentes/navbarScroll/NavScroll';
import Nossa from '../img/quemsomos/nossa-historia.png'
import Nosso from '../img/quemsomos/valores.png'
import NossoValor from '../img/quemsomos/nossos-valores.png'
import QUEM from '../img/quemsomos/quem-somos.png'
import DIF from '../img/quemsomos/DIFERENCIAL.png'
import DIFE from '../img/quemsomos/dif2.png'
import TIME from '../img/quemsomos/timeline-jampti.png'
import Banner from "../componentes/banner/Banner-tow.jsx";

function Quem() {
  return (
    <>
     <NavScroll id="menu"/>
     <main>
      <section className='mt-5 mb-5'>
        <img className='center mt-5 ms-sm-5 mb-5 ' src={QUEM} alt="" style={{width:"50%" , marginLeft:"70px"}} />
        <Banner banner="Nós somos uma Integradora de soluções de tecnologia , segurança eletrônica e infra de TI, com 18 anos de história.
Atendemos clientes dos setores público e privado , com inovação seguimos desenvolvendo soluções ."/>
      </section>
     <section className='mt-5 d.flex  mb-5'>
        <img className='ms-5 flex-start' src={Nossa} alt="" style={{width:"40%"}} />
        <img className=' flex-end ' src={TIME} alt="" style={{width:"90%" , margin:"5%"}} />
      </section>
      <section className='mt-5 mb-5 d.flex flex-sm-row justify-content-center align-middle flex-column'>
        <img className='ms-5 w-50' src={Nosso} alt=""  />
        <img className=' p-5 ' src={NossoValor} alt="" style={{width:"70%", marginLeft:"15%"}} /> 
      </section>
      <section className='mt-5 d-flex flex-column flex-sm-row  me-5   mb-5'>
       
        <div className='mt-5 d-flex flex-column flex-end me-5 p-4  mb-5'>
          <img className=' mt-5 ms-5 mb-5 float-end w-100' src={DIF} alt=""  />
        <p className='d.flex mt-5 ms-5 '>Acompanhamos os movimentos de mudança do mercado de segurança eletrônica e da revolução digital, através da inteligência artificial.
         Nosso objetivo é oferecer qualidade de vida as pessoas a partir do bom atendimento, otimização de processos no trabalho e acesso a mais segurança em todas as áreas.</p>
      
        </div>
         <div className='mt-5 d-flex  flex-start me-5   mb-5'>
        <img className='ms-5 sm:float-start' src={DIFE} alt="" style={{width:"90%"}} />
        </div>
        </section>
     </main>
     <Footer/>
    </>

  );
}

export default Quem;