import Footer from "../../componentes/footer/Footer"
import NavScroll from "../../componentes/navbarScroll/NavScroll"
import Equipamentos from "../img/ativos/equipamentos.png";
import EquipamentosBanner from "../img/ativos/datacenter.png";
import EquipamentosTiposA from "../img/ativos/servidorRede.png";
//import EquipamentosTiposB from "../img/ativos/switchRede.png";
import EquipamentosTiposC from "../img/ativos/roteadorwiriles.png";
const EquipamentosAtivos = (props) =>{
    return(
        <>
        <NavScroll/>
        <main className="w-100">

<img className="w-50 my-5 ms-5" src={Equipamentos} srcSet="" alt="" />
<h1 className=" ms-5 my-4 gohti">O que é Equipamentos Ativos?</h1>
<p className="text-secondary gohtic w-75  mx-auto justify" >&#160; A definição de <span className="text-danger gohtic">Equipamentos Ativos </span>
 são todos aqueles que gerem o tráfego de dados que passam pelos <span className="text-danger gohtic"> Equipamentos Passivos.</span><br/>
portanto, todos os equipamentos <span className="text-danger gohtic">geradores, receptores de códigos ou conversor de sinais elétricos ou óticos.</span></p>

<div className="d-flex flex-column my-5 mx-auto w-100 ">
    <h1 className="  mx-auto  my-4 gohtic"><span className="text-danger gohtic">Tipos de equipamentos</span> </h1>
    
    <div className="w-75 my-5 d-flex justify-content-around mx-auto ">
        <div className="m-5 d-flex flex-column justify-content-center">
            <img className="w-50 my-5 mx-auto" src={EquipamentosTiposA} srcSet="" alt="" />
            <h6 className="text-center">Servidores</h6>
        </div>
        <div className="m-5 d-flex flex-column justify-content-center">
            <img className="w-50 my-5 mx-auto" src={EquipamentosTiposA} srcSet="" alt="" />
            <h6 className="text-center">Switch</h6>
        </div>
        <div className="m-5 d-flex flex-column justify-content-center">
            <img className="w-75 my-5 mx-auto" src={EquipamentosTiposC} srcSet="" alt="" />
            <h6 className="text-center">Roteador wireless</h6>
        </div>
    </div>
    <div className="w-100 my-5 d-flex">
    <img className="w-50 my-5 mx-auto" src={EquipamentosBanner} srcSet="" alt="" />
</div>
</div>
<div className="d-flex d-none">
<div className="w-100 my-5 d-flex flex-column">
<h1 className="  mx-auto  my-5 gohtic"> Geração Fotovoltaica <span className="text-danger gohtic">ON-grid</span></h1>
    <img className="w-50 my-5 mx-auto" src={Equipamentos} srcSet="" alt="" />
</div>
<div className="w-100 my-5 d-flex flex-column">
<h1 className="  mx-auto  my-4 gohtic"> Geração Fotovoltaica <span className="text-danger gohtic">Off-grid</span></h1>
    <img className="w-50 my-5 mx-auto" src={Equipamentos} srcSet="" alt="" />
</div>
</div>
</main>
        <Footer/>
        </>
    )
}
export default EquipamentosAtivos;