
import { Link } from 'react-router-dom'
import Logo from "../../img/logo.png";
import "./nav.css"

function NavScroll() {
  /*const hikvision = '';
  const intelbras = "";*/
  const jamptiOS = "http://www.jamptisystem.com/jampti-os";
  const pdvSystem = "../../router/controleDeAcesso"
  const openInNewTab = url => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <nav class="navbar navbar-expand-lg bg-light border-bottom border-danger" >
      <div class="container-fluid">
          <a class="navbar-brand " href="#one"><img src={Logo} alt="" srcset="" /></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav m-auto mb-2 mb-lg-0">
          <li class="nav-item">
              <Link className='nav-link gohtic'style={{fontSize:"16px"}}  to="/">Home</Link>
            </li>
              {/*<li class="nav-item">
                <Link className='nav-link' to="/Servicos">Servirços</Link> 
                <Link href='#solucao' to="/" className='nav-link' >Soluçoes</Link>
              </li>
              <li class="nav-item">
                <Link className='nav-link' to="/segmentos">Segmentos</Link> 
                <Link href='#segmentos' className='nav-link' to="/">Segmentos</Link> 
                
              </li>*/}
            <li class="nav-item">
              <Link className='nav-link gohtic'style={{fontSize:"16px"}}  to="/Certificacos">Nossas Certificaçoes</Link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link gohtic dropdown-toggle" href="#one" role="button" data-bs-toggle="dropdown" aria-expanded="false"style={{fontSize:"16px"}} >
                sistemas
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#one">
                </a></li>
                <li><a class="dropdown-item gohtic"style={{fontSize:"16px"}}  href="#one" onClick={()=>openInNewTab(jamptiOS)}>jamptios</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item gohtic"style={{fontSize:"16px"}}  href="#one" onClick={()=>openInNewTab(pdvSystem)}>PDVSystem</a></li>
              </ul>
            </li>
            <li class="nav-item">
                {/*<Link className='nav-link' to="/Servicos">Servirços</Link>  <a href='#parceiros' className='nav-link' >Parceiros</a>*/}
               
              </li>
              <li class="nav-item">
              <Link className='nav-link gohtic 'style={{fontSize:"16px"}}  to="/Blog" >Blog</Link>
            </li>
            <li class="nav-item">
              <Link className='nav-link gohtic ' to="/quem" style={{fontSize:"16px"}} >Quem somos</Link>
            </li>
            {/*<li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#one" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                parceiros
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href='#one' onClick={()=>openInNewTab(hikvision)} >hikivision</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab(intelbras)}>intelbras</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>dell</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>dell</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
              </ul>
            </li>*/} 
            
          </ul>

          <form class="d-flex" role="search">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button class="btn btn-outline-success" type="submit">Search</button>
          </form>
        </div>
      </div>
    </nav>




  );
}

export default NavScroll;

