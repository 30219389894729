
import './style.css';
function Cards(props) {
  return (
    <div class="card px-0 my-2 border-white " style={{width:"16rem"}}>
    <img src={props.imagem} class="card-img-top mt-5 m-auto  " style={{width:"10rem" , height:"10rem"}}  alt="..."/>
    <div class="card-body">
      <h6 className=' xl text-center mb-5 gothic jamp-color'>{props.title}<br/>{props.title1}</h6>
      <p class="card-text justify lh-lg gothic">&#160; &#160;&#160;{props.descricao}</p>
    </div>
  </div>
  );
}

export default Cards;