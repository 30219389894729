
import Footer from "../../componentes/footer/Footer"
import NavScroll from "../../componentes/navbarScroll/NavScroll"
const SegurançaEletronica = (props) =>{
return(
    <>
    <NavScroll/>
    <Footer/>
    </>
)
}
export default SegurançaEletronica;