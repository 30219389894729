import "./textos.css";

function TextosBanner(props) {
    return(
        <>
        <div>
            <p ><img src={props.img} alt="" srcset="" /></p>
        </div>
        
        </>
    )
}
export default TextosBanner;