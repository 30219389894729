
import dell from "../../img/PARCEIROS/carroselparceiros.png"
import furukawa from "../../img/PARCEIROS/carroselparceiros1.png"
import apc from "../../img/PARCEIROS/carroselparceiros2.png"
/*import hik from "../../img/PARCEIROS/hikivision.png"
import hp from "../../img/PARCEIROS/HP.png"
import intel from "../../img/PARCEIROS/intelbras.png"
import kasp from "../../img/PARCEIROS/kaspersky.png"
import multi from "../../img/PARCEIROS/multilaser.png"
import nex from "../../img/PARCEIROS/nexans.png"
import sms from "../../img/PARCEIROS/sms.png"*/
function carousel() {
  return (
    <div id="carouselExampleControls" class="carousel slide bg-Secondary " data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="3000">
          <img src={dell} class="d-block w-75 m-auto" alt="..." />
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img src={furukawa} class="d-block w-75 m-auto" alt="..." />
        </div>
        <div class="carousel-item" data-bs-interval="3000">
          <img src={apc} class="d-block w-75 m-auto" alt="..." />
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

  );
}

export default carousel;