
import slide1 from "../../img/slide/slide.png"
import slide2 from "../../img/slide/slide01.png"
import slide3 from "../../img/slide/slide02.png"
function UncontrolledExample() {
  return (
    <div>
        <div id="carouselExampleDark" class="carousel carousel-dark slide " data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner " >
    <div class="carousel-item active" data-bs-interval="5000"style={{height:"550px"}}>
      <img src={slide1} class="d-block w-100 img-fluid " alt="..." style={{marginTop:"-120px"}}/>
      <div class="carousel-caption d-none d-md-block " style={{backgroundColor:"rgba(185, 58, 62, 0.555)" , borderRadius:"10px"}}>
      <h5 className="mt-1 fs-2 my-1 " style={{color:"rgb(212, 209, 209)"}}>Cabeamento Estruturado</h5>
        <p className=" fs-5 my-1" style={{color:"rgb(212, 209, 209)" }}>Cabeamento estruturado, também conhecido pela sigla CBE, é a disciplina que estuda a disposição organizada e padronizada de conectores e meios de transmissão para redes de informática</p>
      </div>
    </div>
    <div class="carousel-item" data-bs-interval="5000"style={{height:"550px"}}>
      <img src={slide2} class="d-block w-100" alt="..." style={{marginTop:"-220px" }}/>
      <div class="carousel-caption d-none d-md-block" style={{backgroundColor:"rgba(185, 58, 62, 0.555)" , borderRadius:"10px"}}>
      <h5 className="mt-1 fs-2 my-1 " style={{color:"rgb(212, 209, 209)"}}>Projetos de Redes e Infraestrutura</h5>
        <p className="-mt-1 fs-5 my-1 " style={{color:"rgb(212, 209, 209)"}}>Criação e implementação de projetos de <br/> Cabeamento Estruturado, Rack's, redes Optica e Wireless.</p>
      </div>
    </div>
    <div class="carousel-item"data-bs-interval="5000"style={{height:"550px"}}>
      <img src={slide3} class="d-block w-100 " alt="..." style={{marginTop:"-100px"}}/>
      <div class="carousel-caption d-none d-md-block"  style={{backgroundColor:"rgba(185, 58, 62, 0.555)" , borderRadius:"10px"}} >
        <h5 className="mt-1 fs-2 my-1 " style={{color:"rgb(212, 209, 209)"}}>Desenvolvimento Web</h5>
        <p className="-mt-1 fs-5 my-1 " style={{color:"rgb(212, 209, 209)"}}>Desenvolvemos desde landing pages a webapps modernas <b/> seguindo os principais Design Patterns focando num desenvolvimento limpo e agil.  .</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden" style={{color:"rgb(212, 209, 209)"}}>Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden" style={{color:"rgb(212, 209, 209)"}}>Next</span>
  </button>
</div>
        </div>
  );
}

export default UncontrolledExample;