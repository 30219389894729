import Footer from "../../componentes/footer/Footer"
import NavScroll from "../../componentes/navbarScroll/NavScroll"
import racks from "../img/racks/racks.png"
const Racks = (props) =>{
    return(
        <>
        <NavScroll/>
    
    <main className="w-100 d-flex flex-column">
        <img className="my-5" src={racks} srcSet="" alt="" style={{ width: "300PX", margin: "0PX AUTO" }} />
        <h4 className="   my-4"style={{ width: "300PX", marginLeft: "550px" }}>O QUE SÃO RACKS?</h4>
        <p className="text-secondary  justify gohtic mx-auto" style={{ width: "700PX",lineHeight:"20px" ,fontSize:"1.1pc"  }}>Rack é uma estrutura geralmente feita de metal no formato de gabinete ou parede, que permite armazenar e organizar os diferentes componentes de instalações da rede,< span className="text-danger gohtic" style={{fontSize:"1.1pc"  }} > como servidores, sistemas de armazenamento, switches, cabos,</span>entre outros equipamentos..</p>

        <div className="d-flex justify-content-around  " style={{ width: "900PX", height: "300px", margin: "30PX AUTO" }}>
          <div className="d-flex flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="w-75" src={racks} alt="" srcset="" />
            <p className="gohtic  fs-6 my-2 " style={{ color: "rgba(185,58,62,255)" }}>Soft-fone</p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="" src={racks} alt="" srcset=""  style={{ width: "70PX", margin: "10PX AUTO" }}/>
            <p className="gohtic  fs-6 my-3 " style={{ color: "rgba(185,58,62,255)" }}>Central telefonica</p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center align-items-center" style={{ width: "220PX", margin: "0PX AUTO" }}>
            <img className="" src={racks} alt="" srcset="" style={{ width: "70PX", margin: "0PX AUTO" }} />
            <p className="gohtic fs-6 my-2 " style={{ color: "rgba(185,58,62,255)" }}>Call Center</p>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center text-center align-items-center">
        <h4 className="  my-1 w-50 " style={{ color: "rgba(185,58,62,255)" }}>TELEFONIA COMUM OU VOIP ?</h4>
          <p className="gohtic  my-5  text-secondary justify"style={{ width: "700PX",fontSize:"1.1pc"  }}>As duas formas de telefonia demonstram duas formas bem diferenciadas de operar. Portanto atentem-se às listas de pós e contras, estabelecidas para escolher qual melhor atende à sua empresa:</p>
          
        </div>
        <div className="w-100 d-flex" style={{ backgroundColor: "rgba(185,58,62,255)" }} >
          <img className="w-25 mx-auto my-5" src={racks} srcSet="" alt="" style={{}} />
        </div>
        <div className="d-flex ">
          <div className="w-100 my-5 d-flex flex-column">
            <h1 className="  mx-auto  my-5 gohtic"> Telefonia  <span className="text-danger gohtic">Convencional</span></h1>

            <img className="w-50 my-3 mx-auto" src={racks} srcSet="" alt="" />
            <div className="d-flex ">
<div className="text-center w-50 d-flex flex-column justify-content-center mx-auto">
<h4 className="fs-2" style={{ color: "rgba(185,58,62,255)" }}>Prós</h4>

<p className="d-flex flex-column text mx-auto w-75 justify">
 Disponibilidade
, Agilidade
, Credibilidade
, Qualidade da ligação
, Sistema de alimentação feito pela central telefônica</p>
</div>
</div>
          </div>
          <div className="w-100 my-5 d-flex flex-column">
            <h1 className="  mx-auto  my-5 gohtic"> Telefonia IP <span className="text-danger gohtic">ou voip</span></h1>
            <img className="w-50 my-5 mx-auto" src={racks} srcSet="" alt="" />
            <div className="d-flex">
<div className="w-50 text-center flex-column justify-content-center mx-auto">
<h4 className="fs-2"  style={{ color: "rgba(185,58,62,255)" }}>Prós</h4>

<p className="d-flex flex-column text mx-auto w-75 justify">Economia de banda com supressão de silêncio
, Baixo custo
, Praticidade
, Facilidade no funcionamento
, Qualidade</p></div>

</div>
          </div>
        </div>
    </main>
    <Footer/>
    </>
    )
}
export default Racks;