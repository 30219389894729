import './blog.css'
import NavScroll from '../componentes/navbarScroll/NavScroll';
import Footer from "../componentes/footer/Footer";
import BannerBlog from "../componentes/banner-blog/Banner-blog";
import Post from '../componentes/post/post';
import BlogImg from "../img/blog.png"
import rack from '../img/rack.jpg'
function Blog() {
  return (
    <>
     <NavScroll/>
     <BannerBlog
     imagem={BlogImg}
     />
     <main className='album py-5 bg-light'>
      <p className='m-auto text-center fs-1 fw-bolder text-danger py-5 gothic '></p>
      <section className='container'>
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3'>
      <Post
      thumbnail={rack}
      titulo="uma postagem"
      postagem="This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
      />
      <Post
      thumbnail={rack}
      titulo="uma postagem"
      postagem="This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
      />
      <Post
      thumbnail={rack}
      titulo="uma postagem"
      postagem="This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
      />
        </div>
      </section>
      <section className='container mt-4'>
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3'>
      <Post
      thumbnail={rack}
      titulo="uma postagem"
      postagem="This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
      />
      <Post
      thumbnail={rack}
      titulo="uma postagem"
      postagem="This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
      />
      <Post
      thumbnail={rack}
      titulo="uma postagem"
      postagem="This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer."
      />
        </div>
      </section>
     </main>
     <Footer/>
    </>

  );
}

export default Blog;