import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import {BrowserRouter as Router, Routes,} from 'react-router-dom';
import Segmentos from "./router/segmentos";
import Servircos from "./router/servircos";
import Certificacos from "./router/Certificacos"

import { Route } from 'react-router-dom';
import Quem from './router/quem';
import Blog from './router/blog';
import Telefonia from './router/solucao/Telefonia';
import SegurançaEletronica from './router/solucao/segurançaEletronica';
import Racks from './router/solucao/Racks';
import IluminacaoEmergencia from './router/solucao/iluminacaoEmergencia';
import Gpon from './router/solucao/Gpon';
import FibraOpitica from './router/solucao/fibraOpitica';
import EquipamentosAtivos from './router/solucao/EquipamentosAtivos';
import EnergiaSolar from './router/solucao/EnergiaSolar';
import ControleDeAcesso from './router/solucao/controleDeAcesso';
import CabeamentoEstruturado from './router/solucao/CabeamentoEstruturado';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <React.StrictMode> 
            <Router>
                <Routes>
                    {/** nav */}
                    <Route path='/' element={<App/>}/>
                    <Route path='/Segmentos' element={<Segmentos/>}/>
                    <Route path='/Servicos' element={<Servircos/>}/>
                    <Route path='/Certificacos' element={<Certificacos/>}/>
                    <Route path='/blog' element={<Blog/>}/>
                    <Route path='/quem' element={<Quem/>}/>
                    {/** nav */}
                    {/** soluçao */}
                    <Route path='/Telefonia' element={<Telefonia/>}/>
                    <Route path='/segurança' element={<SegurançaEletronica/>}/>

                    <Route path='/Racks' element={<Racks/>}/>
                    <Route path='/iluminaçao' element={<IluminacaoEmergencia/>}/>

                    <Route path='/Gpon' element={<Gpon/>}/>
                    <Route path='/Fibra' element={<FibraOpitica/>}/>

                    <Route path='/equipamentos' element={<EquipamentosAtivos/>}/>
                    <Route path='/energia' element={<EnergiaSolar/>}/>

                    <Route path='/controle' element={<ControleDeAcesso/>}/>
                    <Route path='/cabeamento' element={<CabeamentoEstruturado/>}/>
                    {/** soluçao */}
                </Routes>
            </Router>
    </React.StrictMode>
  
);


