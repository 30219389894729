import './style.css';
import logo from "./img/logo.png"
import logoFoot from "./img/logo-foot.png"
import facebook from "./img/facebook-vermelho-24.png";
import instagram from "./img/canal-vermelho-24.png";
import linkdin from "./img/linkedin-vermelho.png";
import youtube from "./img/insta-vermelho-24.png";
import phone from "./img/phone-call.png"
import home from "./img/predio.png"
import local from "./img/local.png"
import plus from "./img/mais.png"
import help from "./img/central-de-atendimento.png"
import { Link } from 'react-router-dom';
function Footer(params) {
    const openInNewTab = url => {
        // 👇️ setting target to _blank with window.open
        window.open(url, '_blank', 'noopener,noreferrer');
      };
      const localizacao = "https://www.google.com/search?q=jampti+endere%C3%A7o&rlz=1C1GCEA_enBR1028BR1028&oq=jampti+endere%C3%A7o&aqs=chrome..69i57j33i160l2.10070j0j7&sourceid=chrome&ie=UTF-8"
      const What ="https://wa.me/558134466342"
    return (
        <div class="container">
        <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 -mb-5 border-top border-danger">
          <div class="col mb-3">
              <div className='w-100 d-flex flex-column'>
                          <img className='w-75 h-25 m-auto mt-5' src={logo} alt="" srcset="" />
                          <div className='w-100 m-auto mt-5 d-flex justify-content-evenly '>
                              <a href="https://www.facebook.com/jamp.ti.56" target="_blank" rel="noopener noreferrer"><img className="troca" src={facebook} alt="" /></a>
                              <a href="https://www.instagram.com/jamp__ti/" target="_blank" rel="noopener noreferrer"><img className="" src={instagram} alt="" /></a>
                              <a href="http://" target="_blank" rel="noopener noreferrer"><img className="" src={linkdin} alt="" /></a>
                              <a href="http://" target="_blank" rel="noopener noreferrer"><img className="" src={youtube} alt="" /></a>
                              
                          </div>
                          </div>
          </div>
      
          <div class="col mb-3">
          <h5 className='ms-1  end'>Endereço</h5>
            <ul className=' w-100'>
            <h5 className="end-left p-1"> Recife</h5>
            <p className="end-left  w-100 p-1"><img src={home} className="" alt="" srcset="" /> R. Vera Cruz, 30 <br/> Pina - Recife - PE <br/> CEP 51110-230</p>
            <li className=" end-left nav-link   w-100 p-1">
            <a className="nav-link " href='#one' onClick={()=>openInNewTab(localizacao)}><img src={local} className="" alt="" srcset="" /> Localização</a></li>
            <hr className='end-left border-danger'></hr>
            <h5 className="end-left p-1 font-weight-bold">Cabo de Santo Agostinho</h5>
            <p className="end-left   w-100 p-1"><img src={home} className="" alt="" srcset="" /> R. Vila Trinta e Nove, 09 <br/> Enseada dos Corais <br/> Cabo de Santo Agostinho - PE <br/> CEP 54500-990</p>
            <li className="end-left nav-link  w-100 p-1">
                <a className="nav-link " href='#one' onClick={()=>openInNewTab(localizacao)}><img src={local}  alt="" srcset="" /> Localização</a></li>    
                
            </ul>
          </div>
      
          <div class="col  mb-3 " >
            <h5>Contatos</h5>
            <ul class="nav flex-column">
            <h5 className=" p-1 fs-6">Recife</h5>
              <li class="nav-item mb-2 flex " style={{height:"124px"}}> <a href="#one" class="nav-link p-0 text-muted" onClick={()=>openInNewTab(What)}> <img src={phone} className="ms-2 ml-2" alt="" srcset="" /> +55(81)3446-6342</a></li>
              
              <h5 className=" p-1 font-weight-bold mt-4 fs-6">Cabo de Santo Agostinho</h5>
              <li class="nav-item mb-2 "><a href="#one" class="nav-link p-0 text-muted" onClick={()=>openInNewTab(What)}> <img src={phone} className="ms-2 ml-2" alt="" srcset="" /> +55(81)3771-4996</a></li>
              
            </ul>
          </div>
      
          <div class="col mb-3">
            <h5><img src={plus} className=" " alt="" srcset="" /> links</h5>
            <ul class="nav flex-column">
              <Link to="" className="link-add">Notícias</Link>
              <Link to="/blog" className="link-add">Blog</Link>
              <Link to="" className="link-add">Área do Cliente</Link>
              <Link to="" className="link-add">Trabalhe Conosco</Link>
              
            </ul>
          </div>
      
          <div class="col mb-3">
          <h5><img src={help} className=" " alt="" srcset="" /> ajuda</h5>
            <ul class="nav flex-column">
              <Link to="" className="link-add">Código de Conduta</Link>
              <Link to="" className="link-add">Política de Privacidade</Link>
              <Link to="" className="link-add">Canal de Transparência Jampti</Link>
              
            </ul>
          </div>      
        </footer>
        <p className='w-100 text-center fs-6  -mt-5 mb-5 m-auto reseved' >&copy; 2023 - Todos os direitos reservados - Desenvolvido por -  <img className=' m-auto' src={logoFoot} alt="" srcset="" /> </p>
          
      </div>
        
    )
};

export default Footer;