import Footer from "../../componentes/footer/Footer"
import NavScroll from "../../componentes/navbarScroll/NavScroll"
function ControleDeAcesso (props){
    return(
        <>
        <NavScroll/>
        <main className="">
           <h1>controle De Acesso</h1>
        </main>
        <Footer/>
        </>
    )
}

export default ControleDeAcesso;