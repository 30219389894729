
import { Link } from 'react-router-dom'
import Logo from "../../img/logo.png";
import "./nav.css"
function NavScroll() {
  /*const hikvision = 'https://www.hikvision.com/pt-br/';
  const intelbras = "https://www.intelbras.com/pt-br/";*/
  const jamptiOS = "http://www.jamptisystem.com/jampti-os";
  const pdvSystem = "https://www.pdvsystem.com"
  const openInNewTab = url => {
    // 👇️ setting target to _blank with window.open
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (



    <nav class="navbar navbar-expand-lg bg-light border-bottom border-danger">
      <div class="container-fluid">
          <a class="navbar-brand " href="#one"><img src={Logo} alt="" srcset="" /></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class=" navbar-nav m-auto mb-2 mb-lg-0">
            
              <li class="nav-item hover-active ">
                {/*<Link className='nav-link' to="/Servicos">Servirços</Link> */}
                <a href='#solucao' className='nav-link gohtic' style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}}  >Soluções</a><div className='hover'></div>
              </li> 
              <li class="nav-item  hover-active">
                {/*<Link className='nav-link gohtic' to="/segmentos">Segmentos</Link> 
                <a href='#servirços' className='nav-link gohtic ' style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}}  to="/segmentos">Serviços</a><div className='hover'></div>*/}
                
              </li>
              <li class="nav-item  hover-active">
                {/*<Link className='nav-link gohtic' to="/segmentos">Segmentos</Link> */}
                <a href='#segmentos' className='nav-link gohtic ' style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}}  to="/segmentos">Segmentos</a><div className='hover'></div>
                
              </li>
             <li class="nav-item  hover-active">
                {/*<Link className='nav-link gohtic' to="/Servicos">Servirços</Link> */}
                <a href='#parceiros' className='nav-link gohtic'  style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}} >Parceiros</a><div className='hover'></div>
              </li>
            <li class="nav-item  hover-active">
              <Link className='nav-link gohtic' style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}}  to="/Certificacos">Nossas Certificaçoes</Link><div className='hover'></div>
            </li>
            <li class="nav-item dropdown  hover-active">
              <a class="nav-link gohtic dropdown-toggle"  style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}}  href="#one" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Sistemas
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#one">
                </a></li>
                <li><a class="dropdown-item" href="#one"   style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}}  onClick={()=>openInNewTab(jamptiOS)}>jamptios</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item disabled "  style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}}  href="#one" onClick={()=>openInNewTab(pdvSystem)}>PDVSystem</a></li>
              </ul><div className='hover'></div>
            </li>
            
              <li class="nav-item  hover-active">
              <Link className='nav-link gohtic ' to="/Blog"  style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}} >Blog</Link><div className='hover'></div>
            </li>
            <li class="nav-item  hover-active">
              <Link className='nav-link gohtic gohtic' to="/quem" style={{fontSize:"13px" , color:"rgb(168, 166, 166)", fontWeight:"100"}} >Quem somos</Link><div className='hover'></div>
            </li>
            {/*<li class="nav-item dropdown">
              <a class="nav-link gohtic dropdown-toggle" href="#one" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                parceiros
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href='#one' onClick={()=>openInNewTab(hikvision)} >hikivision</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab(intelbras)}>intelbras</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>dell</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>dell</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
                <li><hr class="dropdown-divider" /></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
                <li><a class="dropdown-item" href="#one" onClick={()=>openInNewTab()}>unknow</a></li>
              </ul>
            </li>*/} 
          </ul>

          <form class="d-flex" role="search">
            <input class="form-control me-2 " type="search" placeholder="Search" aria-label="Search" />
            <button class="btn btn-outline-success " type="submit">Search</button>
          </form>
        </div>
      </div>
    </nav>




  );
}

export default NavScroll;

