
const Post = (props)=>{
    return(
        <>
          <div class="col">
          <div class="card shadow-sm">
             <img src={props.thumbnail} class="bd-placeholder-img card-img-top" width="100%" height="225" alt="" srcset="" />
            <div class="card-body">
                <h6>{props.titulo}</h6>
              <p class="card-text">{props.postagem}</p>
              <div class="d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button type="button" class="btn btn-sm btn-outline-secondary">curti</button>
                  <button type="button" class="btn btn-sm btn-outline-secondary">comenta</button>
                </div>
                <small class="text-muted">9 mins</small>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}
export default Post;